// src/styles/theme.js

import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#315CAC', // The blue color used in the buttons
    },
    background: {
      default: '#f5f5f5', // Light grey background
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        fullWidth: true,
        margin: 'normal',
      },
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            backgroundColor: 'white',
            borderRadius: '16px',
            '& fieldset': {
              borderColor: 'rgba(0, 0, 0, 0.23)',
            },
            '&:hover fieldset': {
              borderColor: 'rgba(0, 0, 0, 0.23)',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#3f51b5',
            },
          },
          '& .MuiInputBase-input': {
            fontSize: '1rem',
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '55px',
          padding: '14px 0',
          fontSize: '1rem',
          height: '56px',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h5: {
          fontWeight: 'bold',
        },
      },
    },
  },
});

export default theme;