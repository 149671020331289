// src/shared/hooks/useUrlParams.js

import { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext';

export const useUrlParams = () => {
  const location = useLocation();
  const { setInvitationToken, setBuildingId } = useContext(AppContext);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const buildingId = searchParams.get('buildingId');

    if (token) {
      setInvitationToken(token);
    }

    if (buildingId) {
      setBuildingId(buildingId);
    }
  }, [location, setInvitationToken, setBuildingId]);
};