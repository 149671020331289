// src/analytics/services/posthog.js

import posthog from 'posthog-js';

export const init = () => {
  posthog.init(process.env.REACT_APP_POSTHOG_API_KEY, {
    api_host: process.env.REACT_APP_POSTHOG_HOST || 'https://app.posthog.com',
    capture_pageview: true,
    autocapture: true,
    session_recording: {
      enabled: true,
      consent_cookie_name: 'posthog_session_recording_consent'
    }
  });
};

export const trackEvent = (eventName, properties = {}) => {
  posthog.capture(eventName, properties);
};

export const identifyUser = (userId, traits = {}) => {
  posthog.identify(userId, traits);
};

export const resetUser = () => {
  posthog.reset();
};

export const isFeatureEnabled = (flagKey, defaultValue = false) => {
  return posthog.isFeatureEnabled(flagKey, defaultValue);
};

export const getFeatureFlagValue = (flagKey) => {
  return posthog.getFeatureFlagPayload(flagKey);
};

export const setAnalyticsGroup = (groupType, groupKey, groupProperties = {}) => {
  posthog.group(groupType, groupKey, groupProperties);
};