// src/features/invitation/services/invitationService.js

import { api } from '../../../supabase/api';

export const invitationService = {
  fetchVisitsForConserje: async (userId) => {
    try {
      if (!userId || typeof userId !== 'string') {
        throw new Error('Invalid user ID');
      }
  
      const workerBuildings = await api.get('worker_buildings', {
        select: 'building_id',
        filter: { worker_id: userId, is_active: true }
      });
  
      if (!workerBuildings || workerBuildings.length === 0) {
        console.warn('No buildings assigned to this conserje');
        return [];
      }
  
      const allVisits = [];
      const visitIds = new Set(); // To keep track of unique visit IDs
  
      for (const building of workerBuildings) {
        const visits = await api.rpc('get_visits_by_building', { input_building_id: building.building_id });
        
        // Filter out duplicate visits
        const uniqueVisits = visits.filter(visit => {
          if (!visitIds.has(visit.visit_id)) {
            visitIds.add(visit.visit_id);
            return true;
          }
          return false;
        });
  
        allVisits.push(...uniqueVisits);
      }
  
      return allVisits;
    } catch (error) {
      console.error('Error fetching visits for conserje:', error);
      throw error;
    }
  },

  checkInVisitor: async (visitId) => {
    if (!visitId) {
      throw new Error('Visit ID is required');
    }

    try {
      const data = await api.rpc('check_in_visitor', { input_visit_id: visitId });
      return data;
    } catch (error) {
      console.error('Error checking in visitor:', error);
      throw error;
    }
  },

  fetchVisitDetails: async (visitId, userId) => {
    try {
      const visits = await api.rpc('get_visit_details', { input_visit_id: visitId });
      return Array.isArray(visits) && visits.length > 0 ? visits[0] : null;
    } catch (error) {
      console.error('Error fetching visit details:', error);
      throw error;
    }
  },
};