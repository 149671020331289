// src/shared/components/AnalyticsWrapper.js

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAnalytics } from '../hooks/useAnalytics';
import { useUrlParams } from '../../shared/hooks/useUrlParams';

const AnalyticsWrapper = ({ children }) => {
  const location = useLocation();
  const { trackPageView } = useAnalytics();
  useUrlParams();  // Process URL parameters

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const context = searchParams.get('token') ? 'invitation' : 
                    searchParams.get('buildingId') ? 'building' : 'normal';
    trackPageView(location.pathname, { context });
  }, [location, trackPageView]);

  return children;
};

export default AnalyticsWrapper;