// src/contexts/AppContext.js

import React, { createContext, useState } from 'react';
import { useAnalytics } from '../analytics/hooks/useAnalytics';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [invitationToken, setInvitationToken] = useState(null);
  const [buildingId, setBuildingId] = useState(null);
  const [apartmentId, setApartmentId] = useState(null);
  const analytics = useAnalytics();

  const clearContext = () => {
    setInvitationToken(null);
    setBuildingId(null);
    setApartmentId(null);
  };

  return (
    <AppContext.Provider 
      value={{ 
        invitationToken,
        setInvitationToken,
        buildingId,
        setBuildingId,
        apartmentId,
        setApartmentId,
        clearContext,
        analytics
      }}
    >
      {children}
    </AppContext.Provider>
  );
};