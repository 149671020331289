export const ROUTES = {
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  DASHBOARD: '/dashboard',
  VISITS: '/dashboard/visits',
  RESERVATIONS: '/dashboard/reservations',
  LAUNDRY: '/dashboard/laundry',
  COMMON_AREAS: '/dashboard/common-areas',
  DELIVERY: '/dashboard/delivery',
  RESIDENTS: '/dashboard/residents',
  CHAT: '/dashboard/chat',
  INCIDENTS: '/dashboard/incidents',
  FINES: '/dashboard/fines',
  METERS: '/dashboard/meters',
  VISIT_DETAIL: '/dashboard/visit/:visitId',
  MANUAL_VISIT_REGISTRATION: '/dashboard/manual-visit-registration',
};