// src/features/invitation/pages/VisitDetail.js

import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, IconButton, Button, Skeleton } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useParams, useLocation } from 'react-router-dom';
import { invitationService } from '../services/invitationService';
import VisitCard from '../components/VisitCard';
import { useNavigation } from '../../../navigation/hooks/useNavigation';
import { ROUTES } from '../../../config/routes';
import { useUser } from '../../../contexts/UserContext';
import { isPast, parseISO } from 'date-fns';

const VisitDetail = () => {
  const [visit, setVisit] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [checkingIn, setCheckingIn] = useState(false);
  const { visitId } = useParams();
  const { user } = useUser();
  const { navigateWithParams } = useNavigation();
  const location = useLocation();

  const fetchVisitDetails = useCallback(async () => {
    if (!visitId || !user || !user.id) {
      setError('No visit ID provided or user not authenticated');
      setLoading(false);
      return;
    }

    setLoading(true);
    try {
      const data = await invitationService.fetchVisitDetails(visitId, user.id);
      setVisit(data);
    } catch (error) {
      console.error('Error fetching visit details:', error);
      setError('Failed to fetch visit details. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, [visitId, user]);

  useEffect(() => {
    fetchVisitDetails();
  }, [fetchVisitDetails]);

  const handleCheckIn = async () => {
    if (!visitId) return;

    setCheckingIn(true);
    try {
      await invitationService.checkInVisitor(visitId);
      await fetchVisitDetails();
    } catch (error) {
      console.error('Error checking in visitor:', error);
      setError('Failed to check in visitor. Please try again.');
    } finally {
      setCheckingIn(false);
    }
  };

  const handleGoBack = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    const from = searchParams.get('from');
    const activeTab = from === 'history' ? 'history' : 'today';
    navigateWithParams(ROUTES.DASHBOARD, { activeTab });
  }, [location.search, navigateWithParams]);

  const isVisitPassed = visit && (visit.invitation_date || visit.check_in_time) && 
    isPast(parseISO(visit.invitation_date || visit.check_in_time));

  if (loading) {
    return (
      <Box sx={{ maxWidth: 600, margin: 'auto', p: 2 }}>
        <Skeleton variant="rectangular" width="100%" height={200} />
        <Skeleton variant="text" width="60%" height={40} sx={{ mt: 2 }} />
        <Skeleton variant="text" width="40%" height={20} sx={{ mt: 1 }} />
        <Skeleton variant="rectangular" width="100%" height={50} sx={{ mt: 2 }} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ maxWidth: 600, margin: 'auto', p: 2 }}>
        <Typography color="error">{error}</Typography>
        <Button onClick={handleGoBack} sx={{ mt: 2 }}>
          Back to Dashboard
        </Button>
      </Box>
    );
  }

  if (!visit) {
    return (
      <Box sx={{ maxWidth: 600, margin: 'auto', p: 2 }}>
        <Typography>Visit not found.</Typography>
        <Button onClick={handleGoBack} sx={{ mt: 2 }}>
          Back to Dashboard
        </Button>
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: 600, margin: 'auto', p: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <IconButton onClick={handleGoBack} sx={{ mr: 1 }}>
          <ArrowBack />
        </IconButton>
        <Typography variant="h6" component="h1" gutterBottom>
          Detalles de visita
        </Typography>
      </Box>
      <VisitCard visit={visit} />
      {visit.status !== 'checked_in' && !isVisitPassed && (
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleCheckIn}
          disabled={checkingIn || isVisitPassed}
          sx={{ mt: 2 }}
        >
          {checkingIn ? 'Registrando...' : 'Registrar Visitante'}
        </Button>
      )}
    </Box>
  );
};

export default React.memo(VisitDetail);