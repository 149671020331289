// src/App.js

import React, { useEffect } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './styles/theme';

import { AppProvider } from './contexts/AppContext';
import { UserProvider } from './contexts/UserContext';
import { NavigationProvider } from './navigation/context/NavigationContext';

import ForgotPassword from './features/auth/pages/ForgotPassword';
import Login from './features/auth/pages/Login';
import Dashboard from './features/dashboard/pages/Dashboard';
import VisitDetail from './features/invitation/pages/VisitDetail';
import ManualVisitRegistration from './features/manualVisitRegistration/pages/ManualVisitRegistration';
import NotFound from './shared/components/NotFound';
import PrivateRoute from './navigation/components/PrivateRoute';
import AuthLayout from './shared/layouts/AuthLayout';
import MainLayout from './shared/layouts/MainLayout';

import { initAnalytics } from './analytics';
import { ROUTES } from './config/routes';
import AnalyticsWrapper from './analytics/components/AnalyticsWrapper';

function App() {
  useEffect(() => {
    initAnalytics();
  }, []);  
  
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <AppProvider>
          <NavigationProvider>
            <UserProvider>
              <AnalyticsWrapper>
                <Routes>
                  <Route element={<AuthLayout />}>
                    <Route path={ROUTES.LOGIN} element={<Login />} />
                    <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
                  </Route>
                  <Route element={<MainLayout />}>
                    <Route 
                      path={ROUTES.DASHBOARD} 
                      element={<PrivateRoute><Dashboard /></PrivateRoute>} 
                    />
                    <Route 
                      path={ROUTES.VISIT_DETAIL} 
                      element={<PrivateRoute><VisitDetail /></PrivateRoute>} 
                    />
                    <Route 
                      path={ROUTES.MANUAL_VISIT_REGISTRATION}
                      element={<PrivateRoute><ManualVisitRegistration /></PrivateRoute>} 
                    />
                  </Route>
                  <Route path="/" element={<Navigate replace to={ROUTES.LOGIN} />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </AnalyticsWrapper>
            </UserProvider>
          </NavigationProvider>
        </AppProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;