// src/features/auth/services/authService.js

import { api } from '../../../supabase/api';
import { supabase } from '../../../supabase/client';

export const authService = {
  async signIn(email, password) {
    try {
      console.log('Attempting to sign in with email:', email);
      const { data, error } = await supabase.auth.signInWithPassword({ email, password });
      if (error) throw error;
      console.log('Sign in successful, user data:', data.user);
      return data.user;
    } catch (error) {
      console.error('Sign in error:', error);
      throw error;
    }
  },

  async signOut() {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
    } catch (error) {
      console.error('Sign out error:', error);
      throw error;
    }
  },

  async signUpWithOtp(email) {
    const temporaryPassword = Math.random().toString(36).slice(-8);
    try {
      console.log('Attempting to sign up user with email:', email);
      const { data, error } = await supabase.auth.signUp({
        email,
        password: temporaryPassword,
        options: {
          data: {
            document_number: 'PENDING',
            is_active: true,
          }
        }
      });
      if (error) throw error;
      console.log('Signup successful. User data:', data);
      return data;
    } catch (error) {
      console.error('Unexpected error during signUp:', error);
      throw error;
    }
  },

  async verifyOtp(email, token) {
    try {
      console.log('Verifying OTP for email:', email);
      const { data, error } = await supabase.auth.verifyOtp({ 
        email, 
        token, 
        type: 'signup' 
      });
      if (error) throw error;
      console.log('OTP verified successfully for:', email);
      return data;
    } catch (error) {
      console.error('Verify OTP error:', error);
      throw error;
    }
  },

  async updateUserPassword(password) {
    try {
      console.log('Starting password update process');
      const { data, error } = await supabase.auth.updateUser({ password });
      if (error) throw error;
      console.log('Password updated successfully for user:', data.user.id);
      return data.user;
    } catch (error) {
      console.error('Error in updateUserPassword:', error);
      throw error;
    }
  },

  async resetPassword(email) {
    try {
      const { error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: `${window.location.origin}/reset-password`,
      });
      if (error) throw error;
    } catch (error) {
      console.error('Reset password error:', error);
      throw error;
    }
  },

  async getUserProfile(userId) {
    try {
      return await api.get('users', {
        filter: { auth_user_id: userId },
        single: true
      });
    } catch (error) {
      console.error('Get user profile error:', error);
      throw error;
    }
  },

  async updateUserProfile(userId, userData) {
    try {
      console.log('Updating user profile for userId:', userId);
      
      const { data, error } = await supabase
        .from('users')
        .update({
          document_number: userData.dni || 'PENDING',
          document_type: userData.documentType || 'dni',
          first_name: userData.firstName,
          last_name: userData.lastName,
          phone: userData.phone,
          user_type: userData.userType || 'visitor',
        })
        .eq('auth_user_id', userId)
        .select()
        .single();

      if (error) throw error;
      
      if (!data) {
        throw new Error('Failed to update user profile');
      }

      console.log('User profile updated successfully:', data);
      return data;
    } catch (error) {
      console.error('Error updating user profile:', error);
      throw error;
    }
  },

  async getSession() {
    try {
      const { data, error } = await supabase.auth.getSession();
      if (error) throw error;
      return data.session;
    } catch (error) {
      console.error('Get session error:', error);
      throw error;
    }
  },

  async retrieveUser() {
    try {
      const { data, error } = await supabase.auth.getUser();
      if (error) throw error;
      return data.user;
    } catch (error) {
      console.error('Get user error:', error);
      throw error;
    }
  },

  // legacy
};