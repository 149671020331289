// src/features/manualVisitRegistration/hooks/useBuilding.js

import { useState, useEffect } from 'react';
import { useUser } from '../../../contexts/UserContext';
import { manualVisitRegistrationService } from '../services/manualVisitRegistrationService';

export const useBuilding = () => {
  const [apartments, setApartments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useUser();

  useEffect(() => {
    const fetchApartments = async () => {
      if (!user || !user.id) {
        setError('No se pudo determinar el usuario actual.');
        setLoading(false);
        return;
      }

      try {
        const workerBuildings = await manualVisitRegistrationService.getWorkerBuildings(user.id);
        
        if (!workerBuildings || workerBuildings.length === 0) {
          setError('No hay edificios asignados a este conserje.');
          setLoading(false);
          return;
        }

        // Use the first assigned building
        const buildingId = workerBuildings[0].building_id;
        const apartmentsData = await manualVisitRegistrationService.fetchApartmentsForBuilding(buildingId);
        setApartments(apartmentsData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching apartments:', error);
        setError('Error al cargar los apartamentos. Por favor, intente de nuevo.');
        setLoading(false);
      }
    };

    fetchApartments();
  }, [user]);

  return { apartments, loading, error };
};