// src/shared/hooks/useAnalytics.js

import { useCallback } from 'react';
import { 
  trackEvent, 
  trackPageView as trackPageViewAnalytics,
  identifyUser, 
  resetUser, 
  isFeatureEnabled, 
  getFeatureFlagValue, 
  setAnalyticsGroup 
} from '..';
import { EVENT_TYPES } from '../config/events';
import { normalizeUrl } from '../../shared/utils/urlNormalizer';

export const useAnalytics = () => {
  const track = useCallback((eventName, properties = {}) => {
    trackEvent(eventName, properties);
  }, []);

  const trackPageView = useCallback((path, additionalProperties = {}) => {
    const normalizedPath = normalizeUrl(path);
    trackPageViewAnalytics(normalizedPath, additionalProperties);
  }, []);

  const identify = useCallback((userId, traits = {}) => {
    identifyUser(userId, traits);
  }, []);

  const reset = useCallback(() => {
    resetUser();
  }, []);

  const checkFeatureFlag = useCallback((flagKey, defaultValue = false) => {
    return isFeatureEnabled(flagKey, defaultValue);
  }, []);

  const getFeatureFlag = useCallback((flagKey) => {
    return getFeatureFlagValue(flagKey);
  }, []);

  const setGroup = useCallback((groupType, groupKey, groupProperties = {}) => {
    setAnalyticsGroup(groupType, groupKey, groupProperties);
  }, []);

  const trackError = useCallback((errorMessage, errorCode) => {
    track(EVENT_TYPES.ERROR_OCCURRED, { errorMessage, errorCode });
  }, [track]);

  const trackUserAction = useCallback((actionType, actionProperties = {}) => {
    track(actionType, actionProperties);
  }, [track]);

  return {
    track,
    trackPageView,
    identify,
    reset,
    checkFeatureFlag,
    getFeatureFlag,
    setGroup,
    trackError,
    trackUserAction
  };
};