// src/features/invitation/pages/VisitList.js

import React, { useState, useEffect, useMemo } from 'react';
import { Box, Button, CircularProgress, Alert, Tabs, Tab, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useNavigation } from '../../../navigation/hooks/useNavigation';
import { useVisits } from '../hooks/useVisits';
import TodayVisits from '../components/TodayVisits';
import FutureVisits from '../components/FutureVisits';
import HistoricalVisits from '../components/HistoricalVisits';
import { ROUTES } from '../../../config/routes';

const VisitList = () => {
  const { navigateWithParams } = useNavigation();
  const location = useLocation();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const { visits, loading, error } = useVisits();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const activeTab = searchParams.get('activeTab');
    if (activeTab === 'history') {
      setTabValue(2);
    } else if (activeTab === 'future') {
      setTabValue(1);
    } else {
      setTabValue(0);
    }
  }, [location.search]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    const tabs = ['today', 'future', 'history'];
    navigate(`${ROUTES.DASHBOARD}?activeTab=${tabs[newValue]}`, { replace: true });
  };

  const handleRegisterClick = () => {
    navigateWithParams(ROUTES.MANUAL_VISIT_REGISTRATION);
  };

  const memoizedTodayVisits = useMemo(() => <TodayVisits visits={visits} />, [visits]);
  const memoizedFutureVisits = useMemo(() => <FutureVisits visits={visits} />, [visits]);
  const memoizedHistoricalVisits = useMemo(() => <HistoricalVisits visits={visits} />, [visits]);

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <Box sx={{ width: '100%', maxWidth: 600, margin: 'auto' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h5">Visitas</Typography>
        <Button 
          variant="contained"
          color="primary"
          onClick={handleRegisterClick}
          sx={{
            minWidth: 'unset',
            padding: '6px 16px',
            fontSize: '0.875rem',
            height: '32px',
            borderRadius: '16px',
            whiteSpace: 'nowrap',
            width: 'auto',
          }}
        >
          Registrar
        </Button>
      </Box>
      <Tabs 
        value={tabValue} 
        onChange={handleTabChange}
        aria-label="visit tabs"
        sx={{ mb: 2 }}
      >
        <Tab label="Hoy" />
        <Tab label="Futuras" />
        <Tab label="Historial" />
      </Tabs>
      <Box>
        {tabValue === 0 && memoizedTodayVisits}
        {tabValue === 1 && memoizedFutureVisits}
        {tabValue === 2 && memoizedHistoricalVisits}
      </Box>
    </Box>
  );
};

export default React.memo(VisitList);