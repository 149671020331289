// src/features/dashboard/components/PremiumFeaturePopup.js

import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button, IconButton } from '@mui/material';
import { WhatsApp, Close } from '@mui/icons-material';

const PremiumFeaturePopup = ({ open, onClose }) => {
  const whatsappNumber = process.env.REACT_APP_WHATSAPP_NUMBER;
  const whatsappMessage = encodeURIComponent('Hola, me interesa obtener más información sobre las funciones premium de Domus');
  const whatsappLink = `https://wa.me/${whatsappNumber}?text=${whatsappMessage}`;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                <Typography variant="h6" component="div" sx={{ fontSize: '1.2rem', mb: 1 }}>
                Funciones Premium
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
        Estás usando la versión básica de Domus. Para acceder a herramientas avanzadas y optimizar la gestión de tu edificio, contáctanos ahora.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', pb: 3 }}>
        <Button
          variant="contained"
          startIcon={<WhatsApp />}
          href={whatsappLink}
          target="_blank"
          rel="noopener noreferrer"
          onClick={onClose}
          sx={{
            bgcolor: '#25D366',
            color: 'white',
            '&:hover': {
              bgcolor: '#128C7E',
            },
            width: '248px',
            height: '38px',
            padding: '2px 12px',
            minHeight: 0,
            minWidth: 0,
            lineHeight: 1,
            fontSize: '0.85rem',
            '& .MuiButton-startIcon': {
              margin: 0,
              marginRight: '4px',
            },
            '& .MuiSvgIcon-root': {
              fontSize: '1rem',
            },
            textTransform: 'none',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          Contactar a Domus
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PremiumFeaturePopup;