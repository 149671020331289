// src/features/dashboard/pages/Dashboard.js

import React, { useEffect } from 'react';
import { Box, Container, CircularProgress } from '@mui/material';
import VisitList from '../../invitation/pages/VisitList';
import { useDashboardAnalytics } from '../hooks/useDashboardAnalytics';
import { useUser } from '../../../contexts/UserContext';
import { useNavigation } from '../../../navigation/hooks/useNavigation';

const Dashboard = () => {
  const { trackDashboardViewed } = useDashboardAnalytics();
  const { user, loading } = useUser();
  const { clearUrlParams } = useNavigation();

  useEffect(() => {
    if (user && !loading) {
      trackDashboardViewed(user.id);
      clearUrlParams();
    }
  }, [user, loading, trackDashboardViewed, clearUrlParams]);

  if (loading) {
    return (
      <Container maxWidth="md" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Box sx={{ mt: 4 }}>
          <VisitList />
        </Box>
        {/* Add more dashboard widgets here */}
      </Box>
    </Container>
  );
};

export default Dashboard;