// src/features/dashboard/hooks/useDashboardAnalytics.js

import { useCallback } from 'react';
import { useAnalytics } from '../../../analytics/hooks/useAnalytics';
import { EVENT_TYPES } from '../../../analytics/config/events';

export const useDashboardAnalytics = () => {
  const { track } = useAnalytics();

  const trackDashboardViewed = useCallback((userId, buildingId) => {
    track(EVENT_TYPES.DASHBOARD_VIEWED, { userId, buildingId });
  }, [track]);

  const trackPremiumFeatureClicked = useCallback((featureName, userId) => {
    track(EVENT_TYPES.PREMIUM_FEATURE_CLICKED, { 
      featureName, 
      userId,
      source: 'sidebar'
    });
  }, [track]);

  const trackPremiumDialogOpened = useCallback((source, userId) => {
    track(EVENT_TYPES.PREMIUM_DIALOG_OPENED, { source, userId });
  }, [track]);

  const trackPremiumContactClicked = useCallback((userId, contactMethod = 'whatsapp') => {
    track(EVENT_TYPES.PREMIUM_CONTACT_CLICKED, { 
      userId, 
      contactMethod 
    });
  }, [track]);

  return {
    trackDashboardViewed,
    trackPremiumFeatureClicked,
    trackPremiumDialogOpened,
    trackPremiumContactClicked,
  };
};