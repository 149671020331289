// src/analytics/index.js

import { EVENT_TYPES } from './config/events';
import { FEATURE_FLAGS } from './config/featureFlags';
import { GROUP_TYPES } from './config/groups';
import * as posthog from './services/posthog';

// This array can be expanded to include other services in the future
const services = [posthog];

export const initAnalytics = () => {
  services.forEach(service => service.init());
};

export const trackPageView = (path) => {
  services.forEach(service => service.trackEvent('$pageview', { path }));
};

export const trackEvent = (eventName, properties = {}) => {
  services.forEach(service => service.trackEvent(eventName, properties));
};

export const identifyUser = (userId, traits = {}) => {
  services.forEach(service => service.identifyUser(userId, traits));
};

export const resetUser = () => {
  services.forEach(service => service.resetUser());
};

export const isFeatureEnabled = (flagKey, defaultValue = false) => {
  // We'll keep using PostHog for feature flags for now
  return posthog.isFeatureEnabled(flagKey, defaultValue);
};

export const getFeatureFlagValue = (flagKey) => {
  // We'll keep using PostHog for feature flags for now
  return posthog.getFeatureFlagValue(flagKey);
};

export const setAnalyticsGroup = (groupType, groupKey, groupProperties = {}) => {
  services.forEach(service => service.setAnalyticsGroup(groupType, groupKey, groupProperties));
};

export { EVENT_TYPES, FEATURE_FLAGS, GROUP_TYPES };