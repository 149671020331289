// src/shared/layouts/MainLayout.js

import React, { useState } from 'react';
import { Box, AppBar, Toolbar, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { Outlet } from 'react-router-dom';
import ProfileMenu from '../components/ProfileMenu';
import Sidebar from '../../features/dashboard/components/Sidebar';
import PremiumFeaturePopup from '../../features/dashboard/components/PremiumFeaturePopup';
import { useAuth } from '../../features/auth/hooks/useAuth';

const MainLayout = () => {
  const { user } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [sidebarOpen, setSidebarOpen] = useState(!isMobile);
  const [popupOpen, setPopupOpen] = useState(false);
  const drawerWidth = 240;

  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handlePremiumFeatureClick = () => {
    setPopupOpen(true);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'transparent', boxShadow: 'none' }}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleSidebarToggle}
              sx={{ mr: 2, color: 'primary.main' }}
            >
              <MenuIcon />
            </IconButton>
            <img 
              src={process.env.PUBLIC_URL + '/images/domus.png'} 
              alt="Domus" 
              style={{ height: '40px' }} 
            />
          </Box>
          <ProfileMenu />
        </Toolbar>
      </AppBar>
      <Box sx={{ display: 'flex', flexGrow: 1, pt: '64px' }}>
        {user && (
          <Sidebar 
            open={sidebarOpen}
            onClose={() => setSidebarOpen(false)}
            onPremiumFeatureClick={handlePremiumFeatureClick}
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
                top: 64,
                height: 'calc(100% - 64px)',
              },
            }}
          />
        )}
        <Box 
          component="main" 
          sx={{ 
            flexGrow: 1,
            width: '100%',
            p: 3,
          }}
        >
          <Outlet />
        </Box>
      </Box>
      <PremiumFeaturePopup open={popupOpen} onClose={() => setPopupOpen(false)} />
    </Box>
  );
};

export default MainLayout;