// src/navigation/components/PrivateRoute.js

import React, { useEffect, useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../features/auth/hooks/useAuth';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { ROUTES } from '../../config/routes';
import { useAnalytics } from '../../analytics/hooks/useAnalytics';

const PrivateRoute = ({ children }) => {
  const { user, loading } = useAuth();
  const location = useLocation();
  const { track } = useAnalytics();

  useEffect(() => {
    if (!loading && !user) {
      track('UNAUTHORIZED_ACCESS_ATTEMPT', { path: location.pathname });
    }
  }, [loading, user, location, track]);

  const renderedElement = useMemo(() => {
    if (loading) {
      return <LoadingSpinner />;
    }

    if (!user) {
      return <Navigate to={ROUTES.LOGIN} state={{ from: location }} replace />;
    }

    return children;
  }, [loading, user, location, children]);

  return renderedElement;
};

export default React.memo(PrivateRoute);